import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { NFT } from '@thirdweb-dev/sdk';
import { usePlotListings } from '../../hooks/usePlotListings';

interface PlotDetailsModalProps {
  plot: NFT;
  onClose: () => void;
}

const PlotDetailsModal = ({ plot, onClose }: PlotDetailsModalProps) => {
  const { listTerritory, buyTerritory } = usePlotListings();
  const [listingPrice, setListingPrice] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [activeTab, setActiveTab] = useState<'details' | 'market'>('details');
  const [confirmAction, setConfirmAction] = useState(false);

  const handleListTerritory = async () => {
    if (!listingPrice) return;
    setIsProcessing(true);
    try {
      await listTerritory(plot.metadata.id.toString(), parseFloat(listingPrice));
      onClose();
    } catch (error) {
      console.error('Error listing territory:', error);
    } finally {
      setIsProcessing(false);
      setConfirmAction(false);
    }
  };

  const handleBuyTerritory = async () => {
    setIsProcessing(true);
    try {
      await buyTerritory(plot.metadata.id.toString());
      onClose();
    } catch (error) {
      console.error('Error buying territory:', error);
    } finally {
      setIsProcessing(false);
      setConfirmAction(false);
    }
  };

  return (
    <AnimatePresence>
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          className="bg-gray-800 rounded-xl max-w-3xl w-full overflow-hidden shadow-2xl"
        >
          {/* Header with Image */}
          <div className="relative h-72">
            <img 
              src={plot.metadata.image as string} 
              alt={plot.metadata.name as string}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-gray-900/80 to-transparent" />
            <button
              onClick={onClose}
              className="absolute top-4 right-4 bg-black/50 text-white p-2 rounded-full hover:bg-black/70 transition-colors"
            >
              ✕
            </button>
            <div className="absolute bottom-4 left-4">
              <h2 className="text-3xl font-bold text-white mb-1">{plot.metadata.name}</h2>
              <p className="text-gray-300">Territory #{plot.metadata.id}</p>
            </div>
          </div>

          {/* Tabs */}
          <div className="border-b border-gray-700">
            <div className="flex">
              <button
                onClick={() => setActiveTab('details')}
                className={`px-6 py-3 font-medium ${
                  activeTab === 'details' 
                    ? 'text-purple-400 border-b-2 border-purple-400' 
                    : 'text-gray-400 hover:text-gray-300'
                }`}
              >
                Details
              </button>
              <button
                onClick={() => setActiveTab('market')}
                className={`px-6 py-3 font-medium ${
                  activeTab === 'market' 
                    ? 'text-purple-400 border-b-2 border-purple-400' 
                    : 'text-gray-400 hover:text-gray-300'
                }`}
              >
                Market
              </button>
            </div>
          </div>

          {/* Content */}
          <div className="p-6 max-h-[60vh] overflow-y-auto">
            {activeTab === 'details' ? (
              <div className="space-y-6">
                {/* Status Badge */}
                <div className={`inline-flex px-3 py-1 rounded-full text-sm font-medium ${
                  plot.metadata.isListed ? 'bg-green-500/20 text-green-400' : 'bg-yellow-500/20 text-yellow-400'
                }`}>
                  {plot.metadata.isListed ? 'Listed for Sale' : 'Not Listed'}
                </div>

                {/* Description */}
                <div>
                  <h3 className="text-lg font-semibold text-purple-400 mb-2">Description</h3>
                  <p className="text-gray-300">{plot.metadata.description}</p>
                </div>

                {/* Coordinates */}
                <div>
                  <h3 className="text-lg font-semibold text-purple-400 mb-2">Location</h3>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-gray-700/50 p-3 rounded-lg">
                      <p className="text-gray-400 text-sm">X Coordinate</p>
                      <p className="text-white font-medium">{(plot.metadata.attributes as any)?.x || 'N/A'}</p>
                    </div>
                    <div className="bg-gray-700/50 p-3 rounded-lg">
                      <p className="text-gray-400 text-sm">Y Coordinate</p>
                      <p className="text-white font-medium">{(plot.metadata.attributes as any)?.y || 'N/A'}</p>
                    </div>
                  </div>
                </div>

                {/* Additional Properties */}
                <div>
                  <h3 className="text-lg font-semibold text-purple-400 mb-2">Properties</h3>
                  <div className="grid grid-cols-2 gap-4">
                    {Object.entries(plot.metadata.attributes || {})
                      .filter(([key]) => !['x', 'y'].includes(key))
                      .map(([key, value]) => (
                        <div key={key} className="bg-gray-700/50 p-3 rounded-lg">
                          <p className="text-gray-400 text-sm capitalize">{key.replace('_', ' ')}</p>
                          <p className="text-white font-medium">{value as string}</p>
                        </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className="space-y-6">
                {plot.metadata.isListed ? (
                  <>
                    <div className="bg-gray-700/30 p-6 rounded-lg">
                      <p className="text-gray-400 mb-1">Current Price</p>
                      <p className="text-3xl font-bold text-white mb-4">
                        {plot.metadata.price as string} ETH
                      </p>
                      {!confirmAction ? (
                        <button
                          onClick={() => setConfirmAction(true)}
                          className="w-full px-6 py-3 bg-purple-500 hover:bg-purple-600 
                                   text-white rounded-lg font-bold transition-colors"
                        >
                          Buy Territory
                        </button>
                      ) : (
                        <div className="space-y-3">
                          <p className="text-yellow-400 text-sm">
                            Are you sure you want to buy this territory for {0.02} ETH?
                          </p>
                          <div className="flex gap-3">
                            <button
                              onClick={handleBuyTerritory}
                              disabled={isProcessing}
                              className="flex-1 px-6 py-3 bg-green-500 hover:bg-green-600 
                                       disabled:bg-gray-600 text-white rounded-lg font-bold"
                            >
                              {isProcessing ? 'Processing...' : 'Confirm Purchase'}
                            </button>
                            <button
                              onClick={() => setConfirmAction(false)}
                              disabled={isProcessing}
                              className="flex-1 px-6 py-3 bg-red-500 hover:bg-red-600 
                                       text-white rounded-lg font-bold"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="space-y-4">
                    <div className="bg-gray-700/30 p-6 rounded-lg">
                      <label className="text-gray-400 block mb-2">Listing Price (ETH)</label>
                      <input
                        type="number"
                        step="0.001"
                        min="0"
                        value={listingPrice}
                        onChange={(e) => setListingPrice(e.target.value)}
                        className="w-full bg-gray-700 text-white px-4 py-3 rounded-lg mb-4"
                        placeholder="Enter price in ETH"
                      />
                      {!confirmAction ? (
                        <button
                          onClick={() => setConfirmAction(true)}
                          disabled={!listingPrice || isProcessing}
                          className="w-full px-6 py-3 bg-purple-500 hover:bg-purple-600 
                                   disabled:bg-gray-600 text-white rounded-lg font-bold"
                        >
                          List Territory
                        </button>
                      ) : (
                        <div className="space-y-3">
                          <p className="text-yellow-400 text-sm">
                            Are you sure you want to list this territory for {listingPrice} ETH?
                          </p>
                          <div className="flex gap-3">
                            <button
                              onClick={handleListTerritory}
                              disabled={isProcessing}
                              className="flex-1 px-6 py-3 bg-green-500 hover:bg-green-600 
                                       disabled:bg-gray-600 text-white rounded-lg font-bold"
                            >
                              {isProcessing ? 'Processing...' : 'Confirm Listing'}
                            </button>
                            <button
                              onClick={() => setConfirmAction(false)}
                              disabled={isProcessing}
                              className="flex-1 px-6 py-3 bg-red-500 hover:bg-red-600 
                                       text-white rounded-lg font-bold"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default PlotDetailsModal; 