import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import { ThirdwebProvider } from "@thirdweb-dev/react";
import { Base } from "@thirdweb-dev/chains";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './App'
import './index.css'

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ThirdwebProvider 
      activeChain={Base}
      clientId={import.meta.env.VITE_THIRDWEB_CLIENT_ID}
    >
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </ThirdwebProvider>
  </React.StrictMode>
); 