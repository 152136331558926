import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useTextSlideshow } from '../hooks/useTextSlideshow';
import Footer from '../components/Footer';

const Home = () => {
  const navigate = useNavigate();

  const handleExploreMetaverse = () => {
    // Temporarily disabled
    // window.open('/sandbox', '_blank');
  };

  const heroTexts = [
    "Welcome to !NFINIT! STUDIOS",
    "Experience Digital Evolution",
    "Build Your Virtual Empire",
    "Join the Future of Web3",
    
  ];

  const heroSubtexts = [
    "Where Digital Dreams Become Reality",
    "Redefining Virtual Experiences",
    "Own, Create, and Thrive",
    "The Next Generation Metaverse"
  ];

  const { currentText: mainText, isAnimating: mainAnimating } = useTextSlideshow(heroTexts);
  const { currentText: subText, isAnimating: subAnimating } = useTextSlideshow(heroSubtexts);

  const features = [
    {
      title: "Territory Ownership",
      description: "Own and customize your 400m² territory in the KAOS metaverse, with future ability to merge multiple territories.",
      icon: "🌍"
    },
    {
      title: "PEEKABOO Characters",
      description: "Explore the metaverse with unique PEEKABOO characters, part of the revitalized project under !NFINIT! STUDIOS.",
      icon: "🎭"
    },
    {
      title: "THE KOOKiZ Fashion",
      description: "Access exclusive fashion brand opportunities and collaborations through THE KOOKiZ collection.",
      icon: "👕"
    },
    {
      title: "$KOOKIE Integration",
      description: "Enhance your experience with trait claims, upgrades, asset purchasing, and collaboration opportunities.",
      icon: "💎"
    }
  ];

  const timelinePreview = [
    {
      date: "Q4 2024",
      title: "PHASE ⏻NE | $KOOKIE",
      description: "Ecosystem Currency Deployment & Initial Distribution. Peekaboos Universe & TRI3UTE Holders Airdropped $KOOKIE based on Holdings.",
      icon: "🍪"
    },
    {
      date: "Q1 2025",
      title: "PHASE TW⏻ | KɅOS UNIVERSE",
      description: "Metaverse Infrastructure Built. The !NFINIT! Foundations are laid with 10,050 Territories Vaulted. Peekaboos Universe & TRI3UTE Holders Airdropped Territories based on Holdings.",
      icon: "🌍"
    },
    {
      date: "Q1 2025",
      title: "PHASE THREE | TERRITORY PRESALES",
      description: "Territory presales begin with guaranteed entry for eligible holders",
      icon: "🌍"
    }
  ];

  const tokenHighlights = [
    {
      title: "Ecosystem Currency",
      description: "Power all transactions within KAOS UNIVERSE, from trait claims to asset purchases",
      icon: "💫"
    },
    {
      title: "Collaboration Tool",
      description: "Enable participation in collaborations and physical purchasing opportunities",
      icon: "🤝"
    },
    {
      title: "Asset Enhancement",
      description: "Upgrade and enhance your territories and characters through $KOOKIE",
      icon: "⚡"
    }
  ];

  const floatingIcons = [
    { icon: "👻", delay: 0 },
    { icon: "💀", delay: 2 },
    { icon: "🦇", delay: 4 },
    { icon: "🕷️", delay: 1 },
    { icon: "🕸️", delay: 3 },
    { icon: "🔮", delay: 2 },
    { icon: "⚔️", delay: 5 },
    { icon: "🗡️", delay: 1 },
    { icon: "🩸", delay: 4 },
    { icon: "🎭", delay: 3 },
    { icon: "🌑", delay: 2 },
    { icon: "🌘", delay: 5 }
  ];

  return (
    <>
      <div className="min-h-screen bg-[#0a0a0a] text-white">
        {/* Floating Background Icons */}
        <div className="fixed inset-0 pointer-events-none overflow-hidden">
          {floatingIcons.map((item, index) => (
            <motion.div
              key={index}
              className="absolute text-4xl opacity-[0.15]"
              initial={{ 
                x: `${Math.random() * 100}vw`, 
                y: `${Math.random() * 100}vh`,
                rotate: 0,
                scale: 1
              }}
              animate={{ 
                x: [`${Math.random() * 100}vw`, `${Math.random() * 100}vw`],
                y: [`${Math.random() * 100}vh`, `${Math.random() * 100}vh`],
                rotate: [0, 360],
                scale: [1, Math.random() * 1.5 + 0.5, 1]
              }}
              transition={{
                duration: Math.random() * 20 + 20,
                repeat: Infinity,
                delay: item.delay,
                ease: "linear"
              }}
            >
              {item.icon}
            </motion.div>
          ))}
        </div>

        {/* Hero Section with Enhanced Background */}
        <section className="relative h-screen flex items-center justify-center bg-gradient-to-r from-red-900/20 via-gray-900 to-red-900/20 overflow-hidden">
          {/* Additional Floating Icons for Hero */}
          <div className="absolute inset-0 pointer-events-none overflow-hidden">
            {[...Array(6)].map((_, index) => (
              <motion.div
                key={`hero-${index}`}
                className="absolute text-6xl opacity-[0.1]"
                initial={{ 
                  x: `${Math.random() * 100}vw`, 
                  y: '100vh',
                  rotate: 0
                }}
                animate={{ 
                  x: `${Math.random() * 100}vw`,
                  y: '-20vh',
                  rotate: 360
                }}
                transition={{
                  duration: Math.random() * 10 + 10,
                  repeat: Infinity,
                  delay: index * 2,
                  ease: "linear"
                }}
              >
                {floatingIcons[Math.floor(Math.random() * floatingIcons.length)].icon}
              </motion.div>
            ))}
          </div>

          <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-20"></div>
          <div className="absolute inset-0 bg-gradient-radial from-transparent via-black/50 to-black/80"></div>
          <div className="absolute -top-40 -right-40 w-96 h-96 bg-red-600/10 rounded-full blur-3xl"></div>
          <div className="absolute -bottom-40 -left-40 w-96 h-96 bg-red-600/10 rounded-full blur-3xl"></div>
          
          <div className="text-center z-10 px-4 relative">
            <motion.h1 
              key={mainText}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: mainAnimating ? 0 : 1, y: mainAnimating ? 20 : 0 }}
              transition={{ duration: 0.5 }}
              className="text-7xl font-bold mb-6 bg-gradient-to-r from-red-500 to-red-800 text-transparent bg-clip-text drop-shadow-[0_0_25px_rgba(220,38,38,0.2)]"
            >
              {mainText}
            </motion.h1>
            <motion.p 
              key={subText}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: subAnimating ? 0 : 1, y: subAnimating ? 20 : 0 }}
              transition={{ duration: 0.5 }}
              className="text-2xl mb-12 text-gray-300"
            >
              {subText}
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className="flex justify-center gap-6"
            >
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-gradient-to-r from-red-700 to-red-900 text-white px-8 py-4 rounded-lg text-lg font-bold shadow-lg hover:shadow-red-500/30 transition-all border border-red-800/50"
                onClick={handleExploreMetaverse}
              >
                Explore Metaverse
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="border-2 border-red-800/50 text-white px-8 py-4 rounded-lg text-lg font-bold hover:bg-red-950/30 transition-all"
              >
                Learn More
              </motion.button>
            </motion.div>
          </div>

          {/* Enhanced Progress Indicators */}
          <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex space-x-3">
            {heroTexts.map((_, index) => (
              <motion.div
                key={index}
                className={`w-3 h-3 rounded-full ${
                  index === heroTexts.indexOf(mainText)
                    ? 'bg-red-600 shadow-[0_0_10px_rgba(220,38,38,0.5)]'
                    : 'bg-gray-800'
                }`}
                whileHover={{ scale: 1.2 }}
              />
            ))}
          </div>
        </section>

        {/* Features Section with Enhanced Visuals */}
        <section className="py-20 bg-gradient-to-b from-[#0a0a0a] via-[#120808] to-[#0a0a0a] relative">
          <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-10"></div>
          <div className="max-w-7xl mx-auto px-4 relative">
            <h2 className="text-5xl font-bold text-center mb-16 bg-gradient-to-r from-red-500 to-red-800 text-transparent bg-clip-text drop-shadow-[0_0_25px_rgba(220,38,38,0.2)]">
              Key Features
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 justify-items-center max-w-4xl mx-auto">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2 }}
                  className="bg-gradient-to-br from-gray-900 to-black p-8 rounded-2xl shadow-xl hover:shadow-red-900/20 transition-all border border-red-900/20 hover:border-red-800/40 w-full backdrop-blur-sm"
                >
                  <div className="text-4xl mb-4">{feature.icon}</div>
                  <h3 className="text-2xl font-bold mb-4 text-red-500">{feature.title}</h3>
                  <p className="text-gray-400">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Timeline Preview Section with Enhanced Visuals */}
        <section className="py-20 bg-gradient-to-b from-[#0a0a0a] via-[#120808] to-[#0a0a0a] relative">
          <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-10"></div>
          <div className="absolute top-0 right-0 w-96 h-96 bg-red-600/5 rounded-full blur-3xl"></div>
          <div className="absolute bottom-0 left-0 w-96 h-96 bg-red-600/5 rounded-full blur-3xl"></div>
          
          <div className="max-w-7xl mx-auto px-4 relative">
            <h2 className="text-5xl font-bold text-center mb-16 bg-gradient-to-r from-red-500 to-red-800 text-transparent bg-clip-text drop-shadow-[0_0_25px_rgba(220,38,38,0.2)]">
              Upcoming Milestones
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
              {timelinePreview.map((milestone, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2 }}
                  className="bg-gradient-to-br from-gray-900 to-black p-8 rounded-2xl shadow-xl border border-red-900/20 hover:border-red-800/40 hover:shadow-red-900/20 transition-all relative overflow-hidden backdrop-blur-sm"
                >
                  <div className="absolute top-0 right-0 bg-red-950/30 px-4 py-2 rounded-bl-lg border-l border-b border-red-800/30">
                    {milestone.date}
                  </div>
                  <div className="text-4xl mb-4">{milestone.icon}</div>
                  <h3 className="text-2xl font-bold mb-4 text-red-500">{milestone.title}</h3>
                  <p className="text-gray-400">{milestone.description}</p>
                </motion.div>
              ))}
            </div>
            <div className="text-center mt-12">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => window.open('/timeline', '_blank')}
                className="bg-gradient-to-r from-red-700 to-red-900 text-white px-8 py-4 rounded-lg text-lg font-bold shadow-lg hover:shadow-red-500/30 transition-all border border-red-800/50"
              >
                View Full Roadmap
              </motion.button>
            </div>
          </div>
        </section>

        {/* About KOOKI Section with Enhanced Visuals */}
        <section className="py-20 bg-gradient-to-b from-[#0a0a0a] via-[#120808] to-[#0a0a0a] relative">
          <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-10"></div>
          <div className="absolute top-0 left-0 w-96 h-96 bg-red-600/5 rounded-full blur-3xl"></div>
          <div className="absolute bottom-0 right-0 w-96 h-96 bg-red-600/5 rounded-full blur-3xl"></div>
          
          <div className="max-w-7xl mx-auto px-4 relative">
            <h2 className="text-5xl font-bold text-center mb-16 bg-gradient-to-r from-red-500 to-red-800 text-transparent bg-clip-text drop-shadow-[0_0_25px_rgba(220,38,38,0.2)]">
              About KOOKI Token
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
              {tokenHighlights.map((highlight, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2 }}
                  className="bg-gradient-to-br from-gray-900 to-black p-8 rounded-2xl border border-red-900/20 hover:border-red-800/40 hover:shadow-red-900/20 transition-all backdrop-blur-sm"
                >
                  <div className="text-4xl mb-4">{highlight.icon}</div>
                  <h3 className="text-2xl font-bold text-red-500 mb-2">{highlight.title}</h3>
                  <p className="text-gray-400">{highlight.description}</p>
                </motion.div>
              ))}
            </div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => window.open('/about-kooki', '_blank')}
              className="bg-gradient-to-r from-red-700 to-red-900 text-white px-8 py-4 rounded-lg text-lg font-bold shadow-lg hover:shadow-red-500/30 transition-all border border-red-800/50 mx-auto block"
            >
              Learn More About KOOKI
            </motion.button>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Home; 