import * as React from 'react';
import { motion } from 'framer-motion';

interface LayoutItem {
  type: 'building' | 'tree' | 'misc';
  position: { x: number; z: number };
  rotation: number;
  scale: number;
}

interface PlotLayoutMapProps {
  items: LayoutItem[];
  plotSize: { width: number; height: number };
  isLoading?: boolean;
}

const itemColors = {
  building: 'bg-purple-500',
  tree: 'bg-green-500',
  misc: 'bg-blue-500'
};

export const PlotLayoutMap: React.FC<PlotLayoutMapProps> = ({
  items,
  plotSize,
  isLoading = false
}) => {
  const normalizePosition = (pos: { x: number; z: number }) => ({
    x: ((pos.x + plotSize.width / 2) / plotSize.width) * 100,
    z: ((pos.z + plotSize.height / 2) / plotSize.height) * 100
  });

  return (
    <div className="bg-gray-800/50 rounded-lg p-4">
      <h4 className="text-lg font-semibold text-white mb-4">Layout Map</h4>
      
      <div className="relative aspect-square w-full border border-gray-700 rounded-lg overflow-hidden bg-gray-900/50">
        {isLoading ? (
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="animate-spin h-8 w-8 border-3 border-purple-500 border-t-transparent rounded-full" />
          </div>
        ) : (
          <>
            {/* Grid Lines */}
            <div className="absolute inset-0 grid grid-cols-8 grid-rows-8">
              {Array.from({ length: 64 }).map((_, i) => (
                <div key={i} className="border border-gray-800/30" />
              ))}
            </div>

            {/* Layout Items */}
            {items.map((item, index) => {
              const pos = normalizePosition(item.position);
              return (
                <motion.div
                  key={index}
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  className={`absolute w-2 h-2 rounded-full ${itemColors[item.type]}`}
                  style={{
                    left: `${pos.x}%`,
                    top: `${pos.z}%`,
                    transform: `translate(-50%, -50%) rotate(${item.rotation}deg) scale(${item.scale})`
                  }}
                />
              );
            })}

            {/* Legend */}
            <div className="absolute bottom-2 right-2 bg-gray-800/80 rounded-lg p-2 text-xs">
              <div className="flex items-center space-x-2">
                <div className={`w-2 h-2 rounded-full ${itemColors.building}`} />
                <span className="text-gray-300">Buildings</span>
              </div>
              <div className="flex items-center space-x-2">
                <div className={`w-2 h-2 rounded-full ${itemColors.tree}`} />
                <span className="text-gray-300">Trees</span>
              </div>
              <div className="flex items-center space-x-2">
                <div className={`w-2 h-2 rounded-full ${itemColors.misc}`} />
                <span className="text-gray-300">Misc</span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}; 