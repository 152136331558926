import * as React from 'react';
import { motion } from 'framer-motion';

interface LayoutStats {
  buildingCount: number;
  treeCount: number;
  miscCount: number;
  totalItems: number;
  coverage: number;
}

interface PlotLayoutPreviewProps {
  layoutId: string | null;
  stats: LayoutStats;
  isGenerating: boolean;
}

export const PlotLayoutPreview: React.FC<PlotLayoutPreviewProps> = ({
  layoutId,
  stats,
  isGenerating
}) => {
  return (
    <div className="bg-gray-800/50 rounded-lg p-4">
      <h4 className="text-lg font-semibold text-white mb-4">Layout Statistics</h4>
      
      {isGenerating ? (
        <div className="flex items-center justify-center h-32">
          <div className="animate-spin h-8 w-8 border-3 border-purple-500 border-t-transparent rounded-full" />
        </div>
      ) : (
        <div className="space-y-3">
          <div className="grid grid-cols-2 gap-3">
            <StatCard
              label="Buildings"
              value={stats.buildingCount}
              icon="🏢"
            />
            <StatCard
              label="Trees"
              value={stats.treeCount}
              icon="🌳"
            />
            <StatCard
              label="Misc Items"
              value={stats.miscCount}
              icon="📦"
            />
            <StatCard
              label="Coverage"
              value={`${stats.coverage}%`}
              icon="📐"
            />
          </div>
          
          <div className="mt-4 pt-4 border-t border-gray-700">
            <div className="flex justify-between text-sm">
              <span className="text-gray-400">Total Items</span>
              <span className="text-white font-semibold">{stats.totalItems}</span>
            </div>
            {layoutId && (
              <div className="flex justify-between text-sm mt-2">
                <span className="text-gray-400">Layout ID</span>
                <span className="text-white font-mono text-xs">{layoutId}</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const StatCard: React.FC<{
  label: string;
  value: string | number;
  icon: string;
}> = ({ label, value, icon }) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    className="bg-gray-700/50 rounded-lg p-3"
  >
    <div className="flex items-center space-x-2">
      <span className="text-xl">{icon}</span>
      <div>
        <p className="text-gray-400 text-xs">{label}</p>
        <p className="text-white font-semibold">{value}</p>
      </div>
    </div>
  </motion.div>
); 