import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { WalletConnection } from './WalletConnection';
import { useAddress, useDisconnect } from '@thirdweb-dev/react';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredLink, setHoveredLink] = useState<string | null>(null);
  const address = useAddress();
  const disconnect = useDisconnect();
  const navigate = useNavigate();

  const isConnected = !!address;

  const navigation = [
    { name: 'Timeline', href: '/timeline' },
    { name: 'About $KOOKIE', href: '/about-kooki' },
    { name: 'FAQ', href: '/faq' }
  ];

  const EnterMetaverseButton = () => (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={() => navigate('/sandbox')}
      className="bg-gradient-to-r from-red-700 to-red-900 text-white px-4 py-2 lg:px-6 lg:py-3 rounded-lg font-bold shadow-lg hover:shadow-red-500/30 transition-all border border-red-800/50 text-sm lg:text-base relative overflow-hidden group"
    >
      <div className="absolute inset-0 bg-gradient-to-r from-red-600/20 to-transparent transform -skew-x-12 transition-transform group-hover:translate-x-full"></div>
      Enter Metaverse
    </motion.button>
  );

  return (
    <nav className="fixed w-full z-50">
      {/* Backdrop with blur */}
      <div className="absolute inset-0 bg-gradient-to-r from-black via-[#120808] to-black opacity-95 backdrop-blur-md border-b border-red-900/20"></div>

      <div className="relative w-full px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-20">
          {/* Logo */}
          <div className="flex-shrink-0">
            <Link 
              to="/" 
              className="text-2xl md:text-3xl font-bold relative group"
            >
              <span className="bg-gradient-to-r from-red-400 via-red-500 to-red-700 text-transparent bg-clip-text drop-shadow-[0_2px_2px_rgba(0,0,0,1)] relative z-10 hover:scale-105 transition-transform">
                 !NFINIT! STUDIOS 
              </span>
              <motion.div 
                className="absolute inset-0 bg-gradient-to-r from-red-500/50 to-red-800/50 blur-xl opacity-0 group-hover:opacity-100 transition-opacity"
                initial={false}
                animate={{ scale: [0.9, 1.1, 0.9] }}
                transition={{ duration: 2, repeat: Infinity }}
              />
              <div className="absolute inset-0 bg-gradient-to-r from-red-500/20 to-red-800/20 blur-md"></div>
            </Link>
          </div>
          
          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-4 lg:space-x-8">
            <div className="flex space-x-4 lg:space-x-6">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  onMouseEnter={() => setHoveredLink(item.name)}
                  onMouseLeave={() => setHoveredLink(null)}
                  className="text-gray-300 hover:text-red-400 transition-colors text-sm lg:text-base relative group"
                >
                  {item.name}
                  <motion.div
                    className="absolute -bottom-1 left-0 w-full h-0.5 bg-red-500 origin-left"
                    initial={{ scaleX: 0 }}
                    animate={{ scaleX: hoveredLink === item.name ? 1 : 0 }}
                    transition={{ duration: 0.2 }}
                  />
                </Link>
              ))}
            </div>

            {/* Actions */}
            <div className="flex items-center space-x-4">
              <WalletConnection />
              {isConnected && <EnterMetaverseButton />}
            </div>
          </div>

          {/* Mobile menu button */}
          <motion.button
            onClick={() => setIsOpen(!isOpen)}
            className="md:hidden relative w-10 h-10 flex items-center justify-center"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <div className="text-gray-300 hover:text-red-400 transition-colors">
              <motion.div
                animate={isOpen ? { rotate: 45, y: 6 } : { rotate: 0, y: 0 }}
                transition={{ duration: 0.2 }}
                className="absolute w-6 h-0.5 bg-current transform"
              />
              <motion.div
                animate={isOpen ? { opacity: 0 } : { opacity: 1 }}
                transition={{ duration: 0.2 }}
                className="absolute w-6 h-0.5 bg-current"
              />
              <motion.div
                animate={isOpen ? { rotate: -45, y: -6 } : { rotate: 0, y: 0 }}
                transition={{ duration: 0.2 }}
                className="absolute w-6 h-0.5 bg-current transform"
              />
            </div>
          </motion.button>
        </div>
      </div>

      {/* Mobile menu */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
            className="relative md:hidden"
          >
            <div className="bg-[#0a0a0a]/95 backdrop-blur-md border-t border-red-900/20 px-2 pt-2 pb-3 space-y-2">
              {navigation.map((item) => (
                <motion.div
                  key={item.name}
                  initial={{ x: -20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.2 }}
                >
                  <Link
                    to={item.href}
                    className="block px-3 py-2 text-gray-300 hover:text-red-400 transition-colors relative group"
                  >
                    <span className="relative z-10">{item.name}</span>
                    <motion.div
                      className="absolute inset-0 bg-red-900/10 rounded-lg"
                      whileHover={{ scale: 1.05 }}
                      transition={{ duration: 0.2 }}
                    />
                  </Link>
                </motion.div>
              ))}
              <div className="px-3 py-2">
                <WalletConnection />
              </div>
              {isConnected && (
                <div className="px-3 py-2">
                  <EnterMetaverseButton />
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Navbar; 