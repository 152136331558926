import { useRef, useEffect } from 'react';
import { UnityBridge, UnityMessage } from '../../utils/unityBridge';

interface UnityCanvasProps {
  onCanvasReady?: (canvas: HTMLCanvasElement) => void;
  onPlotClick?: (plotData: any) => void;
}

export const UnityCanvas: React.FC<UnityCanvasProps> = ({ onCanvasReady, onPlotClick }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    console.log('🔄 Setting up Unity message handlers');
    
    // Handler function for Unity messages
    const handleUnityMessage = (jsonMessage: string) => {
      console.log('🎮 Unity message received:', jsonMessage);
      try {
        const message = JSON.parse(jsonMessage);
        if (message.action === 'PLOT_SELECTED' && onPlotClick) {
          console.log('🎯 Plot selected:', message.data);
          onPlotClick(message.data);
        }
      } catch (error) {
        console.error('❌ Error parsing Unity message:', error);
      }
    };

    // Set up both handlers
    (window as any).handleUnityMessage = handleUnityMessage;
    (window as any).dispatchReactUnityEvent = handleUnityMessage;

    // Register with UnityBridge as well
    UnityBridge.registerHandler('PLOT_SELECTED', (data: any) => {
      console.log('🎯 Plot selected through UnityBridge:', data);
      if (onPlotClick) {
        onPlotClick(data);
      }
    });

    return () => {
      console.log('🧹 Cleaning up Unity handlers');
      (window as any).handleUnityMessage = undefined;
      (window as any).dispatchReactUnityEvent = undefined;
      UnityBridge.unregisterHandler('PLOT_SELECTED');
    };
  }, [onPlotClick]);

  useEffect(() => {
    if (canvasRef.current && onCanvasReady) {
      console.log('🎨 UnityCanvas is ready');
      onCanvasReady(canvasRef.current);
    }
  }, [onCanvasReady]);

  return (
    <div className="absolute inset-0">
      <div id="unity-container" className="w-full h-full">
        <canvas 
          ref={canvasRef}
          id="unity-canvas" 
          className="w-full h-full"
          style={{ 
            cursor: 'none',
            touchAction: 'none',
            backgroundColor: '#231F20'
          }}
        />
      </div>
    </div> 
  );
};

export default UnityCanvas; 