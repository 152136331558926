import { motion } from 'framer-motion';
import Footer from '../components/Footer';

const Timeline = () => {
  const timelineEvents = [
    {
      date: "Q4 2024",
      title: "PHASE ⏻NE | $KOOKIE",
      description: "Ecosystem Currency Deployment & Initial Distribution. Peekaboos Universe & TRI3UTE Holders Airdropped $KOOKIE based on Holdings.",
      status: "Completed",
      icon: "🍪"
    },
    {
      date: "Q1 2025",
      title: "PHASE TW⏻ | KɅOS UNIVERSE",
      description: "Metaverse Infrastructure Built. The !NFINIT! Foundations are laid 🧱 with 10,050 Territories Vaulted. Peekaboos Universe & TRI3UTE Holders Airdropped Territories based on Holdings.",
      status: "Completed",
      icon: "🌍"
    },
    {
      date: "Q1 2025",
      title: "PHASE THREE | TERRITORY PRESALES",
      description: "Territory presales begin for early adopters",
      status: "Coming Soon",
      icon: "🌍"
    },
    {
      date: "Q1 2025",
      title: "PHASE F⏻UR | TERRITORY PUBLIC RELEASE",
      description: "Public release of territories in the KɅOS UNIVERSE",
      status: "Coming Soon",
      icon: "🌍"
    },
    {
      date: "Q1 2025",
      title: "PHASE FIVE | THE PEEKABOOs RISE",
      description: "Introduction of THE PEEKABOOs characters",
      status: "Coming Soon",
      icon: "👻"
    },
    {
      date: "Q1 2025",
      title: "PHASE SIX | THE KOOKiZ",
      description: "Launch of THE KOOKiZ collection",
      status: "Coming Soon",
      icon: "👕"
    },
    {
      date: "Q1/2 2025",
      title: "PHASE SEVEN | METAVERSE / CHARACTER / NFT / $KOOKIE CONNECTS!",
      description: "!NFINIT! HUB OPENS • Our dedicated !NFINIT! STUDIOS HUB opens & other owned servers migrate into one.",
      status: "Coming Soon",
      icon: "🔌"
    },
    {
      date: "Q2 2025",
      title: "PHASE EIGHT | THE !NFINIT! WAREHOUSE",
      description: "Launch of the !NFINIT! WAREHOUSE platform",
      status: "Planned",
      icon: "🏭"
    },
    {
      date: "Q2 2025",
      title: "PHASE NINE | THE !NFINIT! BUILDING SITE",
      description: "Introduction of building and customization tools",
      status: "Planned",
      icon: "🧱"
    },
    {
      date: "Q3/4 2025",
      title: "THE !NFINIT! FUTURE",
      description: "🔁 THE KɅOS MERGE\n🧑‍🧒‍🧒 WAREHOUSE ONBOARDING\n🕹️ KɅOS GAMING & EVENTS\n🧢 THE KOOKiZ STYLE | EXCLUSIVE FASHION BRAND ACCESS & BENEFITS",
      status: "Planned",
      icon: "📆"
    }
  ];

  return (
    <>
      <div className="min-h-screen bg-black text-white py-20">
        <div className="max-w-7xl mx-auto px-4">
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-5xl font-bold mb-12 bg-gradient-to-r from-red-600 to-red-900 text-transparent bg-clip-text text-center"
          >
            ⏻ !NFINIT! STUDIOS ⏻
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-2xl text-center mb-16 text-red-500"
          >
            • FASHION | ART | TECHNOLOGY •
          </motion.p>
          
          <div className="relative">
            <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-gradient-to-b from-red-800 via-red-600 to-red-900"></div>
            
            <div className="space-y-16">
              {timelineEvents.map((event, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className={`flex items-center ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`}
                >
                  <div className={`w-1/2 ${index % 2 === 0 ? 'pr-12 text-right' : 'pl-12'}`}>
                    <div className="bg-gradient-to-br from-gray-900 to-black p-6 rounded-2xl shadow-xl border border-red-900/30 hover:border-red-600/50 hover:shadow-red-900/20 transition-all">
                      <div className="text-4xl mb-4">{event.icon}</div>
                      <h3 className="text-2xl font-bold text-red-500 mb-2">{event.title}</h3>
                      <p className="text-gray-400 mb-2 whitespace-pre-line">{event.description}</p>
                      <div className={`inline-block px-3 py-1 rounded-full text-sm font-semibold
                        ${event.status === 'Completed' ? 'bg-green-900/30 text-green-400 border border-green-800' :
                          event.status === 'In Progress' ? 'bg-blue-900/30 text-blue-400 border border-blue-800' :
                          event.status === 'Coming Soon' ? 'bg-red-900/30 text-red-400 border border-red-800' :
                          'bg-gray-900/30 text-gray-400 border border-gray-800'}`}>
                        {event.status}
                      </div>
                    </div>
                  </div>
                  <div className="w-4 h-4 bg-red-600 rounded-full absolute left-1/2 transform -translate-x-1/2 z-10 shadow-lg shadow-red-600/50"></div>
                  <div className={`w-1/2 ${index % 2 === 0 ? 'pl-12' : 'pr-12 text-right'}`}>
                    <h4 className="text-xl font-bold text-red-400">{event.date}</h4>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Timeline; 