import { useState } from 'react';
import { motion } from 'framer-motion';
import { UnityBridge } from '../../../utils/unityBridge';
import { toast } from 'react-hot-toast';

export const CameraPanel = () => {
  const [cameraHeight, setCameraHeight] = useState(10);
  const [moveSpeed, setMoveSpeed] = useState(5);

  const handleCameraHeight = (height: number) => {
    UnityBridge.sendMessage({
      action: 'SET_CAMERA_HEIGHT',
      data: { height }
    });
    setCameraHeight(height);
  };

  const handleMoveSpeed = (speed: number) => {
    UnityBridge.sendMessage({
      action: 'SET_MOVE_SPEED',
      data: { speed }
    });
    setMoveSpeed(speed);
  };

  return (
    <div className="p-6 space-y-6">
      <h3 className="text-lg font-bold text-white">Camera Settings</h3>

      <div className="space-y-4">
        <div className="space-y-2">
          <label className="text-gray-300 text-sm">Height</label>
          <input
            type="range"
            min="5"
            max="20"
            value={cameraHeight}
            onChange={(e) => handleCameraHeight(Number(e.target.value))}
            className="w-full accent-purple-500"
          />
          <div className="flex justify-between text-sm text-gray-400">
            <span>5m</span>
            <span>{cameraHeight}m</span>
            <span>20m</span>
          </div>
        </div>

        <div className="space-y-2">
          <label className="text-gray-300 text-sm">Movement Speed</label>
          <input
            type="range"
            min="1"
            max="10"
            value={moveSpeed}
            onChange={(e) => handleMoveSpeed(Number(e.target.value))}
            className="w-full accent-purple-500"
          />
          <div className="flex justify-between text-sm text-gray-400">
            <span>Slow</span>
            <span>{moveSpeed}x</span>
            <span>Fast</span>
          </div>
        </div>
      </div>
    </div>
  );
}; 