import * as React from 'react';
import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { usePlotLayout } from '../../hooks/usePlotLayout';
import { PlotLayoutPreview } from './PlotLayoutPreview';
import { UnityBridge } from '../../utils/unityBridge';
import { PlotLayoutMap } from './PlotLayoutMap';
import { LayoutItem } from '../../types/layout';
import { UnityMessage } from '../../utils/unityBridge';

interface PlotLayoutManagerProps {
  plotId: string;
  onClose: () => void;
}

const DensitySlider: React.FC<{
  label: string;
  value: number;
  onChange: (value: number) => void;
}> = ({ label, value, onChange }) => (
  <div className="space-y-2">
    <div className="flex justify-between">
      <label className="text-gray-300 text-sm">{label}</label>
      <span className="text-gray-400 text-sm">{value}%</span>
    </div>
    <input
      type="range"
      min="0"
      max="100"
      value={value}
      onChange={(e) => onChange(Number(e.target.value))}
      className="w-full h-2 bg-gray-700 rounded-lg appearance-none cursor-pointer accent-purple-500"
    />
  </div>
);

export const PlotLayoutManager: React.FC<PlotLayoutManagerProps> = ({ plotId, onClose }) => {
  const { 
    isGenerating, 
    currentLayoutId, 
    error, 
    densities,
    generateNewLayout, 
    saveCurrentLayout, 
    loadLayout,
    updateDensities 
  } = usePlotLayout(plotId);
  
  const [savedLayouts, setSavedLayouts] = useState<string[]>([]);
  const [layoutStats, setLayoutStats] = useState({
    buildingCount: 0,
    treeCount: 0,
    miscCount: 0,
    totalItems: 0,
    coverage: 0
  });
  const [layoutItems, setLayoutItems] = useState<LayoutItem[]>([]);
  const [plotSize, setPlotSize] = useState({ width: 100, height: 100 });

  useEffect(() => {
    const subscriptions = [
      UnityBridge.subscribe('LAYOUT_STATS_UPDATED', (data: UnityMessage['data']) => {
        if (data.stats) {
          setLayoutStats(data.stats);
        }
      }),
      UnityBridge.subscribe('LAYOUT_MAP_UPDATED', (data: UnityMessage['data']) => {
        if (data.layoutItems) {
          setLayoutItems(data.layoutItems);
        }
        if (data.plotSize) {
          setPlotSize(data.plotSize);
        }
      })
    ];

    handleRequestStats();

    return () => subscriptions.forEach(unsubscribe => unsubscribe());
  }, [plotId]);

  const handleGenerateLayout = async () => {
    try {
      await generateNewLayout();
    } catch (error) {
      // Error is handled in the hook
    }
  };

  const handleSaveLayout = async () => {
    try {
      const layoutId = await saveCurrentLayout();
      setSavedLayouts(prev => [...prev, layoutId]);
    } catch (error) {
      // Error is handled in the hook
    }
  };

  const handleRequestStats = () => {
    UnityBridge.requestLayoutStats(plotId);
    UnityBridge.requestLayoutMap(plotId);
  };

  return (
    <div className="bg-gray-800/90 backdrop-blur-sm rounded-lg p-6 w-full max-w-md">
      <h3 className="text-2xl font-bold text-white mb-6">Layout Manager</h3>

      {/* Density Controls */}
      <div className="space-y-4 mb-6">
        <h4 className="text-lg font-semibold text-white">Density Settings</h4>
        <DensitySlider
          label="Buildings"
          value={densities.buildings}
          onChange={(value) => updateDensities({ buildings: value })}
        />
        <DensitySlider
          label="Trees"
          value={densities.trees}
          onChange={(value) => updateDensities({ trees: value })}
        />
        <DensitySlider
          label="Misc Items"
          value={densities.misc}
          onChange={(value) => updateDensities({ misc: value })}
        />
      </div>

      {/* Error Message */}
      {error && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="bg-red-500/10 border border-red-500/20 rounded-lg p-3 mb-4"
        >
          <p className="text-red-400 text-sm">{error}</p>
        </motion.div>
      )}

      <div className="space-y-4">
        {/* Generate Layout Button */}
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={handleGenerateLayout}
          disabled={isGenerating}
          className="w-full bg-purple-500 hover:bg-purple-600 disabled:bg-purple-500/50 text-white px-4 py-3 rounded-lg transition-colors flex items-center justify-center space-x-2"
        >
          {isGenerating ? (
            <>
              <div className="animate-spin h-5 w-5 border-2 border-white border-t-transparent rounded-full" />
              <span>Generating...</span>
            </>
          ) : (
            <span>Generate New Layout</span>
          )}
        </motion.button>

        {/* Save Layout Button */}
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={handleSaveLayout}
          disabled={isGenerating}
          className="w-full bg-green-500 hover:bg-green-600 disabled:bg-green-500/50 text-white px-4 py-3 rounded-lg transition-colors"
        >
          Save Current Layout
        </motion.button>

        {/* Saved Layouts List */}
        {savedLayouts.length > 0 && (
          <div className="mt-6">
            <h4 className="text-lg font-semibold text-white mb-3">Saved Layouts</h4>
            <div className="space-y-2 max-h-48 overflow-y-auto">
              {savedLayouts.map((layoutId) => (
                <motion.button
                  key={layoutId}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => loadLayout(layoutId)}
                  className={`w-full text-left px-4 py-2 rounded-lg transition-colors ${
                    currentLayoutId === layoutId
                      ? 'bg-purple-500 text-white'
                      : 'bg-gray-700 text-gray-200 hover:bg-gray-600'
                  }`}
                >
                  Layout {new Date(parseInt(layoutId.split('_')[1])).toLocaleString()}
                </motion.button>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Close Button */}
      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        onClick={onClose}
        className="mt-6 w-full bg-gray-700 hover:bg-gray-600 text-white px-4 py-2 rounded-lg transition-colors"
      >
        Close
      </motion.button>

      <div className="grid grid-cols-1 gap-6 mt-6">
        <PlotLayoutPreview
          layoutId={currentLayoutId}
          stats={layoutStats}
          isGenerating={isGenerating}
        />
        <PlotLayoutMap
          items={layoutItems}
          plotSize={plotSize}
          isLoading={isGenerating}
        />
      </div>
    </div>
  );
}; 