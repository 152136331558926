import { useState, useMemo } from 'react';
import { usePlotListings } from '../../hooks/usePlotListings';
import PlotFilters from './PlotFilters';
import { motion } from 'framer-motion';
import PlotDetailsModal from './PlotDetailsModal';
import { NFT } from '@thirdweb-dev/sdk';

export const PlotGrid = () => {
  const { territories, isLoading, error } = usePlotListings();
  const [selectedPlot, setSelectedPlot] = useState<NFT | null>(null);
  const [sortBy, setSortBy] = useState('newest');
  const [priceRange, setPriceRange] = useState({ min: 0, max: Infinity });
  const [statusFilter, setStatusFilter] = useState('all');

  // Debug log
  console.log('Territories:', territories);

  const filteredTerritories = useMemo(() => {
    if (!territories || territories.length === 0) return [];
    
    let filtered = [...territories];

    // Apply price filter
    filtered = filtered.filter(territory => {
      const price = territory.metadata?.price ? Number(territory.metadata.price) : 0;
      return price >= priceRange.min && price <= priceRange.max;
    });

    // Apply status filter
    if (statusFilter !== 'all') {
      filtered = filtered.filter(territory => 
        statusFilter === 'listed' ? territory.metadata?.isListed : !territory.metadata?.isListed
      );
    }

    // Apply sorting
    filtered.sort((a, b) => {
      switch (sortBy) {
        case 'price-asc':
          return (Number(a.metadata?.price) || 0) - (Number(b.metadata?.price) || 0);
        case 'price-desc':
          return (Number(b.metadata?.price) || 0) - (Number(a.metadata?.price) || 0);
        case 'oldest':
          return Number(a.metadata?.id || 0) - Number(b.metadata?.id || 0);
        default: // newest
          return Number(b.metadata?.id || 0) - Number(a.metadata?.id || 0);
      }
    });

    return filtered;
  }, [territories, sortBy, priceRange, statusFilter]);

  if (isLoading) {
    return (
      <div className="text-center py-8">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-500 mx-auto mb-4"></div>
        <p className="text-gray-400">Loading territories...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-8 text-red-400">
        <p>Error loading territories: {error}</p>
      </div>
    );
  }

  if (!territories || territories.length === 0) {
    return (
      <div className="text-center py-8">
        <p className="text-gray-400">No territories available</p>
      </div>
    );
  }

  return (
    <div>
      <PlotFilters 
        onSortChange={setSortBy}
        onPriceRangeChange={(min, max) => setPriceRange({ min, max })}
        onStatusFilter={setStatusFilter}
      />

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredTerritories.map((territory) => (
          <motion.div
            key={territory.metadata?.id}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className="bg-gray-800 rounded-lg overflow-hidden shadow-lg hover:shadow-purple-500/20 transition-shadow"
            //onClick={() => setSelectedPlot(territory)}
          >
            <div className="relative">
              <img 
                src={territory.metadata?.image || '/placeholder-image.png'}
                alt={territory.metadata?.name || `Territory #${territory.metadata.id}`}
                className="w-full h-48 object-cover"
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.src = '/placeholder-image.png';
                }}
              />
              {territory.metadata?.isListed && (
                <div className="absolute top-2 right-2 bg-green-500 text-white px-2 py-1 rounded-full text-sm">
                  Listed
                </div>
              )}
            </div>
            <div className="p-4">
              <h3 className="text-xl font-bold text-white mb-2">
                {territory.metadata?.name || `Territory #${territory.metadata.id}`}
              </h3>
              <p className="text-gray-400 mb-4 line-clamp-2">
                {territory.metadata?.description || 'No description available'}
              </p>
              <div className="flex justify-between items-center">
                <span className="text-purple-400 font-bold">
                  {territory.metadata?.price ? `${territory.metadata.price} ETH` : 'Not Listed'}
                </span>
                <button
                  className="px-4 py-2 bg-purple-500 hover:bg-purple-600 text-white rounded-lg transition-colors"
                >
                  View Details
                </button>
              </div>
            </div>
          </motion.div>
        ))}
      </div>

      {selectedPlot && (
        <PlotDetailsModal
          plot={selectedPlot}
          onClose={() => setSelectedPlot(null)}
        />
      )}
    </div>
  );
}; 