import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Timeline from './pages/Timeline';
import Sandbox from './pages/Sandbox';
import AboutKooki from './pages/AboutKooki';
import Marketplace from './pages/Marketplace';
import { ThirdwebProvider } from "@thirdweb-dev/react";
import Disclaimer from './pages/Disclaimer';
import FAQ from './pages/FAQ';

function App() {
  console.log("App");
  return (
    <ThirdwebProvider
      clientId={import.meta.env.VITE_THIRDWEB_CLIENT_ID}
      activeChain="base"
    >
      <div>
        <Router>
          <Toaster position="bottom-right" />
          <div className="min-h-screen bg-gray-900 text-white">
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/timeline" element={<Timeline />} />
              <Route path="/sandbox" element={<Sandbox />} />
              <Route path="/about-kooki" element={<AboutKooki />} />
              <Route path="/marketplace" element={<Marketplace />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/disclaimer" element={<Disclaimer />} />
            </Routes>
          </div>
        </Router>
      </div>
    </ThirdwebProvider>
  );
}

export default App;
