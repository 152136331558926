import  { useState } from 'react';
import { PlotLayoutManager } from '../components/plot/PlotLayoutManager';
import { PlotGrid } from '../components/marketplace/PlotGrid';
import ErrorBoundary from '../components/ErrorBoundary';
import Footer from '../components/Footer';

const Marketplace = () => {
  const [selectedPlotId, setSelectedPlotId] = useState<string | null>(null);

  return (
    <>
      <div className="container mx-auto px-4 py-8">
        <ErrorBoundary message="Failed to load marketplace">
          <div className="space-y-12">
            <h1 className="text-4xl font-bold mb-8 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">
              KɅOS Territory Marketplace
            </h1>
            
            <PlotGrid />

            {selectedPlotId && (
              <PlotLayoutManager 
                plotId={selectedPlotId} 
                onClose={() => setSelectedPlotId(null)} 
              />
            )}
          </div>
        </ErrorBoundary>
      </div>
      <Footer />
    </>
  );
};

export default Marketplace;