import * as  React from 'react';
import { Component, ReactNode } from 'react';

interface Props {
    message: string;
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

export default class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return <div>{this.props.message}</div>;
        }
        return this.props.children;
    }
} 