import * as React from 'react';
import { useEffect, useState, useCallback, useRef } from 'react';
import { UnityConfig } from '../types/unity';
import { toast } from 'react-hot-toast';
import { LoadingSpinner } from './ui/LoadingSpinner';
import { UnityCanvas } from './unity/UnityCanvas';
import { UnitySidebar } from './unity/UnitySidebar';

declare global {
  interface Window {
    createUnityInstance: any;
    unityInstance: any;
  }
}

interface UnityGameProps {
  config: UnityConfig;
}

export const UnityGame = React.memo(({ config }: UnityGameProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [error, setError] = useState<Error | null>(null);
  const [selectedPlotData, setSelectedPlotData] = useState<any>(null);
  const isInitialized = useRef(false);

  const handleUnityError = useCallback((error: Error) => {
    setError(error);
    setIsLoading(false);
    toast.error('Failed to load Unity game');
    console.error('Unity error:', error);
  }, []);

  const initUnity = useCallback(async (canvas: HTMLCanvasElement) => {
    if (isInitialized.current || window.unityInstance) {
      console.log('Unity already initialized, skipping...');
      return;
    }

    console.log('Initializing Unity');
    try {
      if (typeof window.createUnityInstance === 'undefined') {
        console.log('Loading Unity loader script');
        const loaderScript = document.createElement('script');
        loaderScript.src = '/unity/KAOSUniverse.loader.js';
        loaderScript.async = true;
        
        await new Promise<void>((resolve, reject) => {
          loaderScript.onload = () => {
            console.log('Unity loader script loaded');
            resolve();
          };
          loaderScript.onerror = reject;
          document.body.appendChild(loaderScript);
        });
      }

      console.log('Creating Unity instance');
      window.unityInstance = await window.createUnityInstance(canvas, {
        ...config,
        devicePixelRatio: window.devicePixelRatio,
      }, (progress: number) => {
        setLoadingProgress(Math.round(progress * 100));
      });

      setIsLoading(false);
      setError(null);
      isInitialized.current = true;
      console.log('Unity initialized successfully');
    } catch (err) {
      handleUnityError(err as Error);
    }
  }, [config, handleUnityError]);

  const handlePlotClick = useCallback((plotData: any) => {
    setSelectedPlotData(plotData);
  }, []);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (window.unityInstance) {
        window.unityInstance.Quit();
        window.unityInstance = null;
        isInitialized.current = false;
      }
    };
  }, []);

  if (error) {
    return (
      <div className="absolute inset-0 flex items-center justify-center bg-gray-900">
        <div className="text-center">
          <p className="text-red-500 mb-4">Failed to load game: {error.message}</p>
          <button 
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="relative h-screen">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-900 z-50">
          <div className="text-center">
            <LoadingSpinner size="lg" />
            <p className="text-white mt-4">Loading KAOS UNIVERSE...({loadingProgress}%)</p>
          </div>
        </div>
      )}
      <UnityCanvas onCanvasReady={initUnity} onPlotClick={handlePlotClick} />
      <UnitySidebar plotData={selectedPlotData} />
    </div>
  );
});  