import { motion } from 'framer-motion';
import Footer from '../components/Footer';

const AboutKooki = () => {
  const tokenomics = [
    { label: "Total Supply", value: "1,000,000,000 KOOKI" },
    { label: "Initial Circulating Supply", value: "100,000,000 KOOKI" },
    { label: "Public Sale", value: "30%" },
    { label: "Team & Advisors", value: "15%" },
    { label: "Development Fund", value: "20%" },
    { label: "Community Rewards", value: "25%" },
    { label: "Marketing", value: "10%" }
  ];

  const utilities = [
    {
      title: "Land Purchases",
      description: "Buy and trade virtual land plots in the KAOS metaverse",
      icon: "🏞️"
    },
    {
      title: "NFT Marketplace",
      description: "Trade virtual assets and collectibles using KOOKI",
      icon: "🎨"
    }
  ];

  const tokenDistribution = [
    { label: "Public Sale", percentage: 30, color: "#9333EA" },  // purple-600
    { label: "Team & Advisors", percentage: 15, color: "#E879F9" }, // pink-400
    { label: "Development Fund", percentage: 20, color: "#7C3AED" }, // violet-600
    { label: "Community Rewards", percentage: 25, color: "#C084FC" }, // purple-400
    { label: "Marketing", percentage: 10, color: "#F0ABFC" }  // pink-300
  ];

  const PieChart = () => {
    let cumulativePercentage = 0;
    
    return (
      <div className="relative w-full h-full flex items-center justify-center">
        <svg viewBox="0 0 100 100" className="w-full h-full transform -rotate-90">
          {tokenDistribution.map((item) => {
            const startAngle = (cumulativePercentage * 360) / 100;
            const endAngle = ((cumulativePercentage + item.percentage) * 360) / 100;
            const x1 = 50 + 40 * Math.cos((startAngle * Math.PI) / 180);
            const y1 = 50 + 40 * Math.sin((startAngle * Math.PI) / 180);
            const x2 = 50 + 40 * Math.cos((endAngle * Math.PI) / 180);
            const y2 = 50 + 40 * Math.sin((endAngle * Math.PI) / 180);
            const largeArcFlag = item.percentage > 50 ? 1 : 0;

            cumulativePercentage += item.percentage;

            return (
              <path
                key={item.label}
                d={`M 50 50 L ${x1} ${y1} A 40 40 0 ${largeArcFlag} 1 ${x2} ${y2} Z`}
                fill={item.color}
                className="hover:opacity-90 transition-opacity cursor-pointer"
              />
            );
          })}
        </svg>
        
        {/* Legend */}
        <div className="absolute -right-64 top-1/2 transform -translate-y-1/2">
          {tokenDistribution.map((item) => (
            <div key={item.label} className="flex items-center mb-2">
              <div 
                className="w-4 h-4 rounded-full mr-2" 
                style={{ backgroundColor: item.color }}
              />
              <span className="text-gray-300 text-sm">
                {item.label} ({item.percentage}%)
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="min-h-screen bg-[#0a0a0a] text-white py-20">
        <div className="max-w-7xl mx-auto px-4 relative">
          <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-10"></div>
          <div className="absolute top-0 right-0 w-96 h-96 bg-red-600/5 rounded-full blur-3xl"></div>
          <div className="absolute bottom-0 left-0 w-96 h-96 bg-red-600/5 rounded-full blur-3xl"></div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mb-16 relative"
          >
            <h1 className="text-5xl font-bold mb-6 bg-gradient-to-r from-red-500 to-red-800 text-transparent bg-clip-text drop-shadow-[0_0_25px_rgba(220,38,38,0.2)]">
              KOOKI Token
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              The native cryptocurrency powering the KAOS UNIVERSE ecosystem, enabling seamless transactions, governance, and rewards.
            </p>
          </motion.div>

          {/* Token Stats */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-20">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="bg-gradient-to-br from-gray-900 to-black p-8 rounded-2xl border border-red-900/20 hover:border-red-800/40 hover:shadow-red-900/20 transition-all backdrop-blur-sm h-full"
            >
              <h3 className="text-2xl font-bold text-red-500 mb-4">Token Price</h3>
              <p className="text-4xl font-bold text-white mb-2">$0.01</p>
              <p className="text-gray-400">Initial Price</p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className="bg-gradient-to-br from-gray-900 to-black p-8 rounded-2xl border border-red-900/20 hover:border-red-800/40 hover:shadow-red-900/20 transition-all backdrop-blur-sm h-full"
            >
              <h3 className="text-2xl font-bold text-red-500 mb-4">Total Supply</h3>
              <p className="text-4xl font-bold text-white mb-2">1B</p>
              <p className="text-gray-400">Fixed Supply</p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
              className="bg-gradient-to-br from-gray-900 to-black p-8 rounded-2xl border border-red-900/20 hover:border-red-800/40 hover:shadow-red-900/20 transition-all backdrop-blur-sm h-full"
            >
              <h3 className="text-2xl font-bold text-red-500 mb-4">Initial Distribution</h3>
              <p className="text-4xl font-bold text-white mb-2">100M</p>
              <p className="text-gray-400">Circulating Supply</p>
            </motion.div>
          </div>

          {/* Tokenomics */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
            className="mb-20"
          >
            <h2 className="text-4xl font-bold mb-12 bg-gradient-to-r from-red-500 to-red-800 text-transparent bg-clip-text drop-shadow-[0_0_25px_rgba(220,38,38,0.2)]">
              Tokenomics
            </h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
              <div className="bg-gradient-to-br from-gray-900 to-black p-8 rounded-2xl border border-red-900/20 hover:border-red-800/40 hover:shadow-red-900/20 transition-all backdrop-blur-sm">
                <ul className="space-y-6">
                  {tokenomics.map((item, index) => (
                    <li key={index} className="flex justify-between items-center border-b border-red-900/20 pb-4">
                      <span className="text-gray-300 text-lg">{item.label}</span>
                      <span className="text-red-400 font-bold text-lg">{item.value}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="bg-gradient-to-br from-gray-900 to-black p-8 rounded-2xl border border-red-900/20 hover:border-red-800/40 hover:shadow-red-900/20 transition-all backdrop-blur-sm">
                <div className="aspect-square relative">
                  <PieChart />
                </div>
              </div>
            </div>
          </motion.div>

          {/* Utility */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
            className="mb-20"
          >
            <h2 className="text-4xl font-bold mb-12 bg-gradient-to-r from-red-500 to-red-800 text-transparent bg-clip-text drop-shadow-[0_0_25px_rgba(220,38,38,0.2)]">
              Token Utility
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {utilities.map((utility, index) => (
                <motion.div
                  key={index}
                  whileHover={{ scale: 1.02 }}
                  className="bg-gradient-to-br from-gray-900 to-black p-8 rounded-2xl border border-red-900/20 hover:border-red-800/40 hover:shadow-red-900/20 transition-all backdrop-blur-sm h-full"
                >
                  <div className="text-5xl mb-6">{utility.icon}</div>
                  <h3 className="text-2xl font-bold text-red-500 mb-4">{utility.title}</h3>
                  <p className="text-gray-400 text-lg">{utility.description}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutKooki; 