import { useState, useEffect } from 'react';
import { UnityBridge, UnityMessage } from '../utils/unityBridge';

interface LayoutDensities {
  buildings: number;
  trees: number;
  misc: number;
}

export const usePlotLayout = (plotId: string) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [currentLayoutId, setCurrentLayoutId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [densities, setDensities] = useState<LayoutDensities>({
    buildings: 30,
    trees: 40,
    misc: 20
  });

  useEffect(() => {
    const subscriptions = [
      UnityBridge.subscribe('LAYOUT_GENERATED', (data: UnityMessage['data']) => {
        if (data.error) {
          setError(data.error);
        }
        setIsGenerating(false);
      }),

      UnityBridge.subscribe('LAYOUT_ERROR', (data: UnityMessage['data']) => {
        setError(data.error || 'Unknown error occurred');
        setIsGenerating(false);
      })
    ];

    return () => {
      subscriptions.forEach(unsubscribe => unsubscribe());
    };
  }, []);

  const generateNewLayout = async () => {
    setError(null);
    setIsGenerating(true);
    try {
      UnityBridge.generateLayout(plotId);
      const success = await UnityBridge.waitForLayoutOperation('LAYOUT_GENERATED');
      if (!success) {
        throw new Error('Layout generation timed out');
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to generate layout');
      setIsGenerating(false);
    }
  };

  const updateDensities = (newDensities: Partial<LayoutDensities>) => {
    setDensities(prev => {
      const updated = { ...prev, ...newDensities };
      UnityBridge.updateLayoutDensity(plotId, updated);
      return updated;
    });
  };

  const saveCurrentLayout = async () => {
    setError(null);
    const layoutId = `${plotId}_${Date.now()}`;
    try {
      UnityBridge.saveLayout(plotId, layoutId);
      const success = await UnityBridge.waitForLayoutOperation('LAYOUT_SAVED');
      if (!success) {
        throw new Error('Layout save timed out');
      }
      setCurrentLayoutId(layoutId);
      return layoutId;
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to save layout');
      throw error;
    }
  };

  const loadLayout = async (layoutId: string) => {
    setError(null);
    try {
      UnityBridge.loadLayout(plotId, layoutId);
      const success = await UnityBridge.waitForLayoutOperation('LAYOUT_LOADED');
      if (!success) {
        throw new Error('Layout load timed out');
      }
      setCurrentLayoutId(layoutId);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to load layout');
      throw error;
    }
  };

  return {
    isGenerating,
    currentLayoutId,
    error,
    densities,
    generateNewLayout,
    saveCurrentLayout,
    loadLayout,
    updateDensities
  };
}; 