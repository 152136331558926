import * as React from 'react';
import { UnityGame } from '../components/UnityGame';
import ErrorBoundary from '../components/ErrorBoundary';

const Sandbox = React.memo(() => {
  const unityConfig = {
    loaderUrl: '/unity/KAOSUniverse.loader.js',
    dataUrl: '/unity/KAOSUniverse.data',
    frameworkUrl: '/unity/KAOSUniverse.framework.js',
    codeUrl: '/unity/KAOSUniverse.wasm',
  };

  return (
    <div className="relative h-screen">
      <ErrorBoundary message="Failed to load sandbox">
        <UnityGame config={unityConfig} />
      </ErrorBoundary>
    </div>
  );
});

export default Sandbox; 