import * as React from 'react';
import { motion } from 'framer-motion';
import Footer from '../components/Footer';

interface FAQItem {
  question: string;
  answer: React.ReactNode;
}

const FAQ = () => {
  const faqs: FAQItem[] = [
    {
      question: "What is !NFINIT! STUDIOS?",
      answer: (
        <div>
          <p className="text-xl font-bold mb-4">FASHION | ART | TECHNOLOGY</p>
          <p>We are a Web 3 Studio specialising in Metaverse, Gaming and Blockchain Technology whilst providing Business & Full stack development consultation.</p>
          <p className="mt-4">We are the Parent Brand representing multiple Web2/3 projects including KɅOS UNIVERSE & THE KOOKiZ.</p>
          <p className="mt-4">Focussed on connecting innovation, talent and entrepreneurship through community growth, collaboration, platform enhancements, AI and externally available initiatives.</p>
          <p className="mt-4">The !NFINIT! STUDIOS Community Hub will open in Discord soon.</p>
        </div>
      )
    },
    {
      question: "What is KɅOS UNIVERSE?",
      answer: (
        <div>
          <p>Formerly Peekaboos Universe; !NFINIT! STUDIOS acquired this struggling project in 2024 and has started building a unique Metaverse for the PEEKABOO characters [and more] to roam.</p>
          <p className="mt-4">Each NFT within the KɅOS UNIVERSE is it's own territory of Land, enabling holders to let their imaginations run wild and create a space that suits them.</p>
          <p className="mt-4">The KɅOS UNIVERSE Project will host a publicly available space in the centre of the Metaverse & Additional Projects will be onboarded throughout the roadmap.</p>
          <p className="mt-4">Each Territory has a surface area of 400m² and multiple territories will have the ability to merge in future plans.</p>
        </div>
      )
    },
    {
      question: "What is THE KOOKiZ?",
      answer: (
        <div>
          <p>Formerly TRI3UTE; !NFINIT! STUDIOS acquired this project in 2024, rebranded, expanded its trait portfolio and maintains its Web2/Web3 Blend Fashion Ambitions.</p>
          <p className="mt-4">Prior to & Post Public Launch, Holders will gain exclusive access to our Founders Creative Fashion Brand.</p>
          <p className="mt-4">A Heavy emphasis on collaboration and partnerships will see THE KOOKiZ establish itself as a recognised fashion brand and support others on their entrepreneurial journeys.</p>
        </div>
      )
    },
    {
      question: "I own a Peekaboos Universe NFT - What happens?",
      answer: (
        <div>
          <ul className="list-disc pl-5 space-y-2">
            <li>1X OG WIP = 1X Airdropped Territory ✅ Complete</li>
            <li>Peekaboos Universe Genesis NFT = 1:1 Migration Ratio - PEEKABOOs by !NFINIT! STUDIOS Coming Soon</li>
            <li>Peekaboos Universe Blackout NFT = 1:1 Migration Ratio - Trait Drops Coming Soon</li>
            <li>$KOOKIE Dropped to wallets ✅ Complete</li>
            <li>Guaranteed Offer Acceptance During PreSales Coming Soon</li>
          </ul>
        </div>
      )
    },
    {
      question: "I own a TRI3UTE NFT - What happens?",
      answer: (
        <div>
          <ul className="list-disc pl-5 space-y-2">
            <li>10+ TRI3UTE NFTs = 1X Airdropped Territory ✅ Complete</li>
            <li>20+ TRI3UTE NFTs = 2X Airdropped Territories ✅ Complete</li>
            <li>50+ TRI3UTE NFTs = 3X Airdropped Territories ✅ Complete</li>
            <li>80+ TRI3UTE NFTs = 4X Airdropped Territories ✅ Complete</li>
            <li>100+ TRI3UTE NFTs = 5X Airdropped Territories ✅ Complete</li>
            <li>$KOOKIE Dropped to wallets ✅ Complete</li>
            <li>Guaranteed Offer Acceptance During PreSales Coming Soon</li>
          </ul>
        </div>
      )
    },
    {
      question: "What is $KOOKIE?",
      answer: (
        <div>
          <p>$KOOKIE is our ecosystem tokenomics which will allow holders to enhance their experience through trait claims, upgrading, asset purchasing, collaboration involvement, physical purchasing and more.</p>
          <p className="mt-4">There is currently no date set for a LP for $KOOKIE.</p>
        </div>
      )
    },
    {
      question: "How do I purchase a 'Territory' NFT?",
      answer: (
        <div>
          <h3 className="text-xl font-bold mb-4">🗺️ KɅOS UNIVERSE | TERRITORIES 🗺️</h3>
          <ul className="list-none space-y-4">
            <li><strong>CHAIN:</strong> Base</li>
            <li><strong>SUPPLY:</strong> 10,050</li>
            <li>
              <strong>PRICE:</strong>
              <ul className="list-disc pl-5 mt-2">
                <li>PreSales & Guaranteed Entry: 0.015 WETH on Base</li>
                <li>Public: 0.025 bETH/WETH on Base</li>
              </ul>
            </li>
            <li>
              <strong>ENTRY:</strong>
              <ul className="list-disc pl-5 mt-2">
                <li>PreSales: A minimum bid of the above is to be placed on the Territory of your choosing and must be owned by !NFINIT! STUDIOS. Offer acceptance will take place once a week and will be announced on 𝕏 / Discord when open.</li>
                <li>Public: TBA</li>
              </ul>
            </li>
          </ul>
        </div>
      )
    }
  ];

  return (
    <>
      <div className="min-h-screen bg-[#0a0a0a] text-white pt-24 pb-20">
        <div className="max-w-4xl mx-auto px-4 relative">
          <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-10"></div>
          <div className="absolute top-0 right-0 w-96 h-96 bg-red-600/5 rounded-full blur-3xl"></div>
          <div className="absolute bottom-0 left-0 w-96 h-96 bg-red-600/5 rounded-full blur-3xl"></div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mb-16 relative"
          >
            <h1 className="text-5xl font-bold mb-6 bg-gradient-to-r from-red-500 to-red-800 text-transparent bg-clip-text drop-shadow-[0_0_25px_rgba(220,38,38,0.2)]">
              Frequently Asked Questions
            </h1>
            <p className="text-xl text-gray-300">
              Your Territory | Your Rules
            </p>
          </motion.div>

          {/* Disclaimer Card */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="bg-gradient-to-br from-gray-900 to-black p-6 rounded-xl mb-12 border border-red-900/20 hover:border-red-800/40 hover:shadow-red-900/20 transition-all backdrop-blur-sm relative"
          >
            <h2 className="text-2xl font-bold mb-4 text-red-500">Disclaimer</h2>
            <div className="text-gray-300 leading-relaxed space-y-4">
              {/* ... existing disclaimer text ... */}
            </div>
          </motion.div>

          {/* FAQ Items */}
          <div className="space-y-6 relative">
            {faqs.map((faq, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 * (index + 3) }}
                className="bg-gradient-to-br from-gray-900 to-black p-6 rounded-xl border border-red-900/20 hover:border-red-800/40 hover:shadow-red-900/20 transition-all backdrop-blur-sm"
              >
                <h3 className="text-2xl font-bold mb-4 text-red-500">{faq.question}</h3>
                <div className="text-gray-300 leading-relaxed">
                  {faq.answer}
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FAQ; 