import { useContract, useAddress, useContractRead } from "@thirdweb-dev/react";
import { TERRITORY_CONTRACT_ADDRESS } from "../utils/constants";
import { useState } from "react";

export const usePlotListings = () => {
  const address = useAddress();
  const { contract: nftContract } = useContract(TERRITORY_CONTRACT_ADDRESS, "edition");
  const { contract: marketContract } = useContract("0x0938053042B9bc036F71E61cce4C681B11000977", "marketplace");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Fetch NFT metadata
  const { data: uri } = useContractRead(nftContract, "uri", [101]);
  console.log("NFT URI:", uri);

  const listTerritory = async (tokenId: string, price: number) => {
    if (isLoading) return; // Prevent multiple calls
    try {
      setIsLoading(true);
      if (!marketContract) throw new Error("Contract not initialized");
      
      const listing = await marketContract.direct.createListing({
        assetContractAddress: TERRITORY_CONTRACT_ADDRESS,
        tokenId,
        buyoutPricePerToken: price,
        currencyContractAddress: "0x0938053042B9bc036F71E61cce4C681B11000977",
        startTimestamp: new Date(),
        listingDurationInSeconds: 86400 * 30,
        quantity: 1,
      });
      return listing;
    } catch (error) {
      console.error("Error listing territory:", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const mockTerritories = Array.from({ length: 100 }, (_, i) => ({
    metadata: {
      uri: uri || "",
      id: (i + 101).toString(),
      name: `Territory #${i + 101}`,
      description: "KɅOS Territory",
      image: uri?.replace("{id}", (i + 101).toString()) || "",
      isListed: false,
      price: "0.025",
      external_url: "",
      background_color: "",
      properties: {}
    },
    owner: address || "",
    type: "ERC1155" as const,
    supply: 1
  }));

  return {
    territories: mockTerritories,
    isLoading,
    listTerritory,
    buyTerritory: async (tokenId: string) => {
      if (!marketContract) throw new Error("Contract not initialized");
      return marketContract.direct.buyoutListing(tokenId, 1);
    },
    error
  };
}; 