import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { CameraPanel } from './panels/CameraPanel';
import { PlotPanel } from './panels/PlotPanel';
import { motion, AnimatePresence } from 'framer-motion';
import { FaCamera, FaMap, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { UnityMessage } from '../../utils/unityBridge';

interface UnitySidebarProps {
  plotData: UnityMessage['data'] | null;
}

export const UnitySidebar: React.FC<UnitySidebarProps> = React.memo(({ plotData }) => {
  const [activePanel, setActivePanel] = useState<'camera' | 'plot' | null>(null);
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    if (plotData) {
      setActivePanel('plot');
      setIsCollapsed(false);
    }
  }, [plotData]);

  return (
    <motion.div
      className="fixed right-0 top-[64px] h-[calc(100vh-64px)] bg-[#0a0a0a]/90 backdrop-blur-sm flex z-50 border-l border-red-900/20"
      initial={{ width: '60px' }}
      animate={{ width: isCollapsed ? '60px' : '400px' }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
    >
      {/* Navigation Strip */}
      <div className="w-[60px] h-full bg-gradient-to-b from-black via-[#120808] to-black flex flex-col items-center py-4 border-r border-red-900/20">
        <button
          onClick={() => {
            setActivePanel('camera');
            setIsCollapsed(false);
          }}
          className={`p-3 rounded-lg mb-2 transition-all hover:scale-105 ${
            activePanel === 'camera' 
              ? 'bg-gradient-to-r from-red-700 to-red-900 text-white shadow-lg shadow-red-500/20' 
              : 'text-gray-400 hover:text-red-400'
          }`}
        >
          <FaCamera size={24} />
        </button>
        
        <button
          onClick={() => {
            setActivePanel('plot');
            setIsCollapsed(false);
          }}
          className={`p-3 rounded-lg mb-2 transition-all hover:scale-105 ${
            activePanel === 'plot' 
              ? 'bg-gradient-to-r from-red-700 to-red-900 text-white shadow-lg shadow-red-500/20' 
              : 'text-gray-400 hover:text-red-400'
          }`}
        >
          <FaMap size={24} />
        </button>

        {/* Collapse Toggle Button */}
        <button
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="mt-auto p-3 text-gray-400 hover:text-red-400 transition-all hover:scale-105"
        >
          {isCollapsed ? <FaChevronLeft size={24} /> : <FaChevronRight size={24} />}
        </button>
      </div>

      {/* Panel Content */}
      <AnimatePresence mode="wait">
        <motion.div
          key={activePanel}
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 20 }}
          transition={{ duration: 0.2 }}
          className="flex-1 p-6 overflow-y-auto"
        >
          {activePanel === 'plot' && plotData ? (
            <PlotPanel data={plotData} />
          ) : activePanel === 'plot' && !plotData ? (
            <div className="flex items-center justify-center h-full text-gray-400">
              <div className="text-center">
                <FaMap size={48} className="mx-auto mb-4 text-red-600/50" />
                <p>No plot data available.<br/>Click on a plot for details.</p>
              </div>
            </div>
          ) : (
            activePanel === 'camera' && <CameraPanel />
          )}
        </motion.div>
      </AnimatePresence>
    </motion.div>
  );
}); 