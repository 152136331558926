import * as React from 'react';
import { UnityMessage } from '../../../utils/unityBridge';
import { FaMapMarkerAlt, FaCube, FaTree, FaBuilding } from 'react-icons/fa';

interface PlotPanelProps {
  data: UnityMessage['data'] | null;
}

export const PlotPanel: React.FC<PlotPanelProps> = ({ data }) => {
  console.log('PlotPanel rendered with data:', data);
  if (!data) {
    return (
      <div className="flex items-center justify-center h-full min-h-screen text-gray-400">
        No plot data available. Click on a plot for details.
      </div>
    );
  }

  const handleCameraView = () => {
    // Call the HandleCameraView function on the manager GameObject
    console.log('Sending message to Unity:', data.plotId);
    const manager = window.unityInstance?.SendMessage('Manager', 'HandleCameraView', data.plotId);
    console.log('Camera view handled for plot ID:', data.plotId);
  };

  const handleToggleCamera = () => {
    // Send message to Unity
    const message = {
      action: "TOGGLE_CAMERA_VIEW",
      data: {}
    };

    // Send to Unity via window message
    window.unityInstance?.SendMessage('Manager', 'HandleToggleCameraView', data.plotId);
  };

  return (
    <div className="flex flex-col h-full">
      {/* Header */}
      <div className="mb-6">
        <h2 className="text-2xl font-bold text-white mb-2">{data.plotId}</h2>
        <div className="flex items-center text-purple-400">
          <FaCube className="mr-2" />
          <span>{data.plotType}</span>
        </div>
      </div>

      {/* Plot Details Card */}
      <div className="bg-gray-800/50 rounded-lg p-4 mb-6 backdrop-blur-sm">
        <h3 className="text-lg font-semibold text-gray-300 mb-4">Plot Details</h3>

        {/* Location */}
        <div className="mb-4">
          <div className="flex items-center text-purple-400 mb-2">
            <FaMapMarkerAlt className="mr-2" />
            <span className="font-medium">Location</span>
          </div>
          <div className="grid grid-cols-3 gap-2 text-sm bg-gray-800/50 rounded p-2">
            <div>
              <span className="text-gray-400">X:</span>
              <span className="text-white ml-1">{data.position.x.toFixed(2)}</span>
            </div>
            <div>
              <span className="text-gray-400">Y:</span>
              <span className="text-white ml-1">{data.position.y.toFixed(2)}</span>
            </div>
            <div>
              <span className="text-gray-400">Z:</span>
              <span className="text-white ml-1">{data.position.z.toFixed(2)}</span>
            </div>
          </div>
        </div>

        {/* Density Stats */}
        <div className="space-y-3">
          {/* Building Density */}
          <div>
            <div className="flex items-center justify-between text-sm mb-1">
              <div className="flex items-center text-gray-300">
                <FaBuilding className="mr-2" />
                <span>Building Density</span>
              </div>
              <span className="text-purple-400">{(data.buildingDensity * 100).toFixed(0)}%</span>
            </div>
            <div className="h-2 bg-gray-700 rounded-full">
              <div
                className="h-full bg-purple-500 rounded-full"
                style={{ width: `${data.buildingDensity * 100}%` }}
              />
            </div>
          </div>

          {/* Tree Density */}
          <div>
            <div className="flex items-center justify-between text-sm mb-1">
              <div className="flex items-center text-gray-300">
                <FaTree className="mr-2" />
                <span>Tree Density</span>
              </div>
              <span className="text-green-400">{(data.treeDensity * 100).toFixed(0)}%</span>
            </div>
            <div className="h-2 bg-gray-700 rounded-full">
              <div
                className="h-full bg-green-500 rounded-full"
                style={{ width: `${data.treeDensity * 100}%` }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Error Message if any */}
      {data.error && (
        <div className="text-red-400 bg-red-900/20 rounded p-3 mb-6">
          {data.error}
        </div>
      )}

      {/* Buy Button */}
      <div className="mt-auto">
        <button
          className="w-full bg-purple-600 hover:bg-purple-700 text-white font-semibold py-3 px-6 rounded-lg transition-colors duration-200 flex items-center justify-center space-x-2"
          onClick={() => console.log('Buy plot clicked')}
        >
          <span>Buy Plot</span>
          <span className="text-purple-300">•</span>
          <span className="text-sm font-normal">$5,000 KOOKIE</span>
        </button>
      </div>
      {/* Camera Toggle Button */}
      <div className="mt-4">
        <button
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg transition-colors duration-200"
          onClick={handleToggleCamera}
        >
          Toggle Camera
        </button>
      </div>
      {/* Camera View Button */}
      <div className="mt-4">
        <button
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg transition-colors duration-200"
          onClick={handleCameraView}
        >
          Focus on Plot
        </button>
      </div>
    </div>
  );
}; 