import { useConnect, metamaskWallet, useWallet, useContract, useTokenBalance, useBalance } from "@thirdweb-dev/react";
import { useEffect, useState } from "react";

const KOOKIE_ADDRESS = "0x0938053042B9bc036F71E61cce4C681B11000977"; // Kookie token address
const BASE_CHAIN_ID = 8453; // Replace with the actual chain ID for Base

export function WalletConnection() {
  const wallet = useWallet();
  const connect = useConnect();
  const { contract } = useContract(KOOKIE_ADDRESS, "token");
  const [walletAddress, setWalletAddress] = useState<string>("");

  useEffect(() => {
    const fetchAddress = async () => {
      if (wallet) {
        const address = await wallet.getAddress();
        setWalletAddress(address);
      }
    };
    fetchAddress();
  }, [wallet]);

  const { data: kookieBalance, isLoading: kookieLoading, error: kookieError } = useTokenBalance(
    contract,
    walletAddress
  );

  const { data: ethBalance, isLoading: ethLoading, error: ethError } = useBalance(walletAddress);

  const handleConnect = async () => {
    try {
      const walletConfig = metamaskWallet();
      await connect(walletConfig, { chainId: BASE_CHAIN_ID });
      console.log("Connected to MetaMask on Base chain");
    } catch (err) {
      console.error("Failed to connect:", err);
    }
  };

  return (
    <div className="flex items-center gap-4">
      <button
        onClick={handleConnect}
        className="bg-blue-500 text-white px-4 py-2 rounded"
      >
        {walletAddress ? (
          <>
            {walletAddress.slice(0, 6)}...{walletAddress.slice(-4)}
          </>
        ) : (
          "Connect MetaMask"
        )}
      </button>

      {walletAddress && (
        <div className="bg-gray-800/50 p-3 rounded-lg">
          <p className="text-sm text-gray-400">KOOKIE Balance</p>
          {kookieLoading ? (
            <p className="text-sm text-yellow-400">Loading...</p>
          ) : kookieError ? (
            <p className="text-sm text-red-400">Error: {(kookieError as Error).message}</p>
          ) : (
            <p className="text-lg font-bold text-purple-400">
              {kookieBalance?.displayValue} KOOKIE
            </p>
          )}
        </div>
      )}
    </div>
  );
}
