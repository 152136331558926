import { motion } from 'framer-motion';
import Footer from '../components/Footer';

const Disclaimer = () => {
  return (
    <>
      <div className="min-h-screen bg-gray-900 text-white py-20">
        <div className="max-w-4xl mx-auto px-4">
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-5xl font-bold mb-12 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text text-center"
          >
            Disclaimer
          </motion.h1>
          
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="space-y-6 text-gray-300 leading-relaxed"
          >
            <p>
              This whitepaper and any related content regarding Infiniti studios, KAOS universe and/or the KOOKiZ including 
              the $KOOKIE tokenomics are provided for informational and entertainment purposes only. Infiniti studios, 
              KAOS universe and the KOOKiZ are not a financial services product and does not offer financial advice, 
              investment recommendations, or guarantees of any kind.
            </p>
            
            <p>
              Infiniti Studios and its affliates, including all representatives and partners, expressly disclaim any 
              liability for financial decisions made based on this information. All potential investors are encouraged 
              to conduct their own independent research and consult with a qualified financial advisor before making 
              any investment decisions.
            </p>
            
            <p>
              Participation in the presale or purchase of NFTs and/or tokenomics involves risk and should be approached 
              with caution. Infiniti Studios assumes no responsibility for any financial losses or consequences arising 
              from the use of this content or participation in any of the associated projects.
            </p>
            
            <p>
              By engaging with this whitepaper, you acknowledge that you are doing so at your own risk and that 
              Infiniti Studios will not be held liable for any losses or damages resulting from your involvement 
              with any of the previously listed or future listed projects associated with Infiniti studios.
            </p>
          </motion.div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Disclaimer; 