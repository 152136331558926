import { motion } from 'framer-motion';

interface PlotFiltersProps {
  onSortChange: (value: string) => void;
  onPriceRangeChange: (min: number, max: number) => void;
  onStatusFilter: (status: string) => void;
}

const PlotFilters = ({ onSortChange, onPriceRangeChange, onStatusFilter }: PlotFiltersProps) => {
  return (
    <div className="bg-gray-800 p-6 rounded-lg mb-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Sort Options */}
        <div>
          <label className="text-gray-300 block mb-2">Sort By</label>
          <select 
            onChange={(e) => onSortChange(e.target.value)}
            className="w-full bg-gray-700 text-white rounded-lg p-2 border border-gray-600"
          >
            <option value="newest">Newest First</option>
            <option value="oldest">Oldest First</option>
            <option value="price-asc">Price: Low to High</option>
            <option value="price-desc">Price: High to Low</option>
          </select>
        </div>

        {/* Price Range */}
        <div>
          <label className="text-gray-300 block mb-2">Price Range (ETH)</label>
          <div className="flex gap-2">
            <input 
              type="number" 
              placeholder="Min"
              onChange={(e) => onPriceRangeChange(Number(e.target.value), Infinity)}
              className="w-1/2 bg-gray-700 text-white rounded-lg p-2 border border-gray-600"
            />
            <input 
              type="number" 
              placeholder="Max"
              onChange={(e) => onPriceRangeChange(0, Number(e.target.value))}
              className="w-1/2 bg-gray-700 text-white rounded-lg p-2 border border-gray-600"
            />
          </div>
        </div>

        {/* Status Filter */}
        <div>
          <label className="text-gray-300 block mb-2">Status</label>
          <div className="flex gap-2">
            <button 
              onClick={() => onStatusFilter('all')}
              className="px-4 py-2 rounded-lg bg-purple-500 hover:bg-purple-600 text-white"
            >
              All
            </button>
            <button 
              onClick={() => onStatusFilter('listed')}
              className="px-4 py-2 rounded-lg bg-gray-700 hover:bg-gray-600 text-white"
            >
              Listed
            </button>
            <button 
              onClick={() => onStatusFilter('unlisted')}
              className="px-4 py-2 rounded-lg bg-gray-700 hover:bg-gray-600 text-white"
            >
              Unlisted
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlotFilters; 