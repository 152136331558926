export interface UnityMessage {
  action: string;
  data: {
    plotId: string;
    plotType: string;
    position: { x: number; y: number; z: number };
    buildingDensity: number;
    treeDensity: number;
    miscDensity: number;
    error: string;
    [key: string]: any; // Allow additional properties
  };
}

type MessageHandler = (data: UnityMessage['data']) => void;

export interface UnityBridgeType {
  // ... other methods ...
  generateLayout(plotId: string): void;
  waitForLayoutOperation(action: string): Promise<boolean>;
  updateLayoutDensity(plotId: string, densities: { buildings: number; trees: number; misc: number }): void;
  saveLayout(plotId: string, layoutId: string): void;
  loadLayout(plotId: string, layoutId: string): void;
  requestLayoutStats(plotId: string): void;
  requestLayoutMap(plotId: string): void;
}

export class UnityBridge {
  private static handlers = new Map<string, ((data: any) => void)[]>();

  static subscribe(action: string, callback: (data: any) => void): () => void {
    const handlers = this.handlers.get(action) || [];
    handlers.push(callback);
    this.handlers.set(action, handlers);

    return () => {
      const currentHandlers = this.handlers.get(action) || [];
      this.handlers.set(action, currentHandlers.filter(h => h !== callback));
    };
  }

  private static eventHandlers: { [key: string]: Function } = {};
  
  public static registerHandler(action: string, handler: Function) {
    console.log(`[UnityBridge] Registering handler for ${action}`);
    this.eventHandlers[action] = handler;
  }

  public static unregisterHandler(action: string) {
    console.log(`[UnityBridge] Unregistering handler for ${action}`);
    delete this.eventHandlers[action];
  }

  public static sendMessage(message: { action: string; data: any }) {
    if (window.unityInstance) {
      const jsonMessage = JSON.stringify(message);
      console.log('[UnityBridge] Sending message to Unity:', jsonMessage);
      window.unityInstance.SendMessage('Manager', 'HandleWebMessage', jsonMessage);
    } else {
      console.warn('[UnityBridge] Unity instance not available');
    }
  }

  public static generateLayout(plotId: string) {
    console.log(`[UnityBridge] Generating layout for plotId: ${plotId}`);
    const message = {
      action: 'GENERATE_LAYOUT',
      data: { plotId }
    };
    this.sendMessage(message);
  }

  public static waitForLayoutOperation(action: string): Promise<boolean> {
    console.log(`[UnityBridge] Waiting for layout operation: ${action}`);
    return new Promise((resolve) => {
      const timeout = setTimeout(() => {
        console.warn(`[UnityBridge] Timeout waiting for action: ${action}`);
        resolve(false); // Resolve as false if the operation times out
      }, 10000); // 10 seconds timeout

      const handler = (data: UnityMessage['data']) => {
        if (data.action === action) {
          clearTimeout(timeout);
          console.log(`[UnityBridge] Action completed: ${action}`);
          resolve(true); // Resolve as true if the operation is successful
        }
      };

      this.registerHandler(action, handler);
    });
  }

  public static updateLayoutDensity(plotId: string, densities: { buildings: number; trees: number; misc: number }) {
    console.log(`[UnityBridge] Updating layout density for plotId: ${plotId}`);
    const message = {
      action: 'UPDATE_LAYOUT_DENSITY',
      data: { plotId, densities }
    };
    this.sendMessage(message);
  }

  public static saveLayout(plotId: string, layoutId: string) {
    console.log(`[UnityBridge] Saving layout for plotId: ${plotId}, layoutId: ${layoutId}`);
    const message = {
      action: 'SAVE_LAYOUT',
      data: { plotId, layoutId }
    };
    this.sendMessage(message);
  }

  public static loadLayout(plotId: string, layoutId: string) {
    console.log(`[UnityBridge] Loading layout for plotId: ${plotId}, layoutId: ${layoutId}`);
    const message = {
      action: 'LOAD_LAYOUT',
      data: { plotId, layoutId }
    };
    this.sendMessage(message);
  }

  public static requestLayoutStats(plotId: string) {
    console.log(`[UnityBridge] Requesting layout stats for plotId: ${plotId}`);
    const message = {
      action: 'REQUEST_LAYOUT_STATS',
      data: { plotId }
    };
    this.sendMessage(message);
  }

  public static requestLayoutMap(plotId: string) {
    console.log(`[UnityBridge] Requesting layout map for plotId: ${plotId}`);
    const message = {
      action: 'REQUEST_LAYOUT_MAP',
      data: { plotId }
    };
    this.sendMessage(message);
  }
}

// export function handleUnityMessage(message: UnityMessage) {
//   console.log('[UnityBridge] Dispatching event:', message);
//   const event = new CustomEvent('unity-message', { detail: message });
//   document.dispatchEvent(event);
// }

// // Bind the function to the global window object
// (window as any).handleUnityMessage = handleUnityMessage;
// console.log('[UnityBridge] handleUnityMessage function bound to window');
 

